




















































































































import { Component, Vue } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import Table from '@/components/Table/Table.vue'
import FButton from '@/components/Button/FButton.vue'
import Alert from '@/components/Alert/Alert.vue'
import { TableColumn } from '@/models/TableColumn';
import { ApiService } from '@/services/ApiService';
import { ServiceCenterPaymentSettings } from '@/models/ServiceCenterPaymentSettings';
import { ProfileService } from '@/services/ProfileService';
import { PaymentSettingsService } from '@/services/PaymentSettingsService'
import { BusyService } from '@/services/BusyService'
import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'

@Component({
  components: {
    CardWithTitle, 
    Table,
    FButton, 
    Alert
  }, 
  metaInfo: () => ({
    title: DynamicTranslationService.paymentSettingsTitle
  }),
})
export default class PaymentSettings extends Vue {
  data: ServiceCenterPaymentSettings[] | null = null

  get tableColumns(): TableColumn[]{
    return  [
      { key: 'payoutLocation', label: i18n.t('R.LIT_CCLIPayoutLocation').toString()},
      { key: 'type', label: i18n.t('R.LIT_Type').toString()},
      { key: 'details', label: i18n.t('R.LIT_Details').toString()},
    ]
  }

  get enableDirectDeposit() { 
    return ProfileService.enableDirectDeposit
  }

  get directDepositStatus() { 
    return PaymentSettingsService.directDepositStatus;
  }
  
  async mounted() { 
    BusyService.showBusy();
    
    if (!ProfileService.profile?.org){ 
      throw Error ('Organization model missing');
    }

    this.data = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id });
    BusyService.hideBusy();
  }
}
